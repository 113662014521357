import React, { Suspense } from 'react';
import { NextPage } from 'next';
import Head from 'next/head';
import { defineMessages, useIntl } from 'react-intl';
import { IndexTop } from './index/IndexTop';
import { Footer } from '../../layouts/Footer';
import { IndexDownloadsInfo } from './index/IndexDownloadsInfo';
import { IndexSupport } from './index/IndexSupport';
import { IndexCTA } from './index/IndexCTA';
import { premiumQuotes } from './premium/quotes';
import { Features } from './features/Features';
import { IndexCarousel } from './index/IndexCarousel';
import { features } from './features/consts';
import { QuotesView } from './premium/QuotesView';
import { BlogPostsResponse } from '../blog/blogPosts';
import { IndexBlogPosts } from './index/IndexBlogPosts';
import { IndexTalkingAboutUs } from './index/IndexTalkingAboutUs';
import { MoveFromToHowToSteps, MoveFromToHowToType, moveHowToSteps } from './move/MoveFromToHowToSteps';
import { spotify } from '../../components/musicServices/services/Spotify';
import { appleMusic } from '../../components/musicServices/services/AppleMusic';
import { CarouselFullPage } from './components/CarouselFullPage';
import { MoveFromToRatings } from './move/MoveFromToRatings';

const { pageTitle } = defineMessages({
  pageTitle: {
    id: 'web.main_title',
    defaultMessage: 'FreeYourMusic: Ultra Simple Transfer of Playlists'
  }
});

export const Home: NextPage<{
  blogPosts: BlogPostsResponse;
}> = React.memo(({ blogPosts }) => {
  const intl = useIntl();

  return (
    <>
      <Head>
        <title>{intl.formatMessage(pageTitle)}</title>
      </Head>
      <IndexTop />
      <IndexTalkingAboutUs />
      <CarouselFullPage steps={moveHowToSteps.length}>
        {(cs) => {
          return (
            <MoveFromToHowToSteps
              from={spotify}
              to={appleMusic}
              currentStep={cs}
              type={MoveFromToHowToType.allMusicLibrary}
            />
          );
        }}
      </CarouselFullPage>
      <Suspense fallback={null}>
        <MoveFromToRatings />
        <IndexDownloadsInfo />
        <Features
          title={intl.formatMessage({
            id: 'web.home.selling-points.title',
            defaultMessage: 'Powerful Playlist  Management Tools'
          })}
          features={features}
        />
        <QuotesView quotes={premiumQuotes} />
        <IndexSupport />

        <IndexCTA />
        <IndexBlogPosts blogPosts={blogPosts} />
        <IndexCarousel />
        <Footer />
      </Suspense>
    </>
  );
});
